html, body {
  font-size: var(--font-size-small);
  line-height: var(--line-height-small);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-normal);
  text-align: center;
  color: var(--color-dark);
}

h1 {
  width: 100%;
  font-size: var(--font-size-large);
  line-height: var(--line-height-large);
  font-family: var(--font-family-primary);
  text-transform: uppercase;
  text-align: center;
  font-weight: var(--font-weight-semibold);
  color: var(--color-dark);
  margin: 0px;

  &.default-margin {
    margin-bottom: 15px;
  }
}

h2 {
  font-size: var(--font-size-middle);
  line-height: var(--line-height-middle);
  letter-spacing: 0.3px;
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-semibold);
  text-align: center;
  text-transform: uppercase;
  color: var(--color-dark);
  margin: 0px;

  &.default-margin {
    margin-bottom: 15px;
  }
}

label {
  color: var(--color-dark);
  margin: 0px;
  padding: 0px;
  font-size: var(--font-size-medium);
  line-height: var(--line-height-medium);
  width: 100%;

  * {
    text-transform: lowercase;
  }
}

a {
  color: var(--color-primary);
  transition: all 0.3s ease-in-out;

  &:hover {
    color: var(--color-primary-shade);
  }
}
