/* cyrillic-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("/assets/fonts/JTURjIg1_i6t8kCHKm45_cJD3gTD_u50.woff2") format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("/assets/fonts/JTURjIg1_i6t8kCHKm45_cJD3g3D_u50.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("/assets/fonts/JTURjIg1_i6t8kCHKm45_cJD3gbD_u50.woff2") format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("/assets/fonts/JTURjIg1_i6t8kCHKm45_cJD3gfD_u50.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("/assets/fonts/JTURjIg1_i6t8kCHKm45_cJD3gnD_g.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/assets/fonts/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2") format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/assets/fonts/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/assets/fonts/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2") format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/assets/fonts/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/assets/fonts/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("/assets/fonts/JTURjIg1_i6t8kCHKm45_bZF3gTD_u50.woff2") format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("/assets/fonts/JTURjIg1_i6t8kCHKm45_bZF3g3D_u50.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("/assets/fonts/JTURjIg1_i6t8kCHKm45_bZF3gbD_u50.woff2") format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("/assets/fonts/JTURjIg1_i6t8kCHKm45_bZF3gfD_u50.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("/assets/fonts/JTURjIg1_i6t8kCHKm45_bZF3gnD_g.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
form, .form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
form .form-fields-group, .form .form-fields-group {
  width: 100%;
}
form app-input-text:not(:last-child), form app-input-select:not(:last-child), form app-input-unbound-select:not(:last-child), form app-action-button:not(:last-child), form .form-fields-group:not(:last-child), .form app-input-text:not(:last-child), .form app-input-select:not(:last-child), .form app-input-unbound-select:not(:last-child), .form app-action-button:not(:last-child), .form .form-fields-group:not(:last-child) {
  margin-bottom: 15px;
}

:root {
  /** colors **/
  --color-primary: #2492f2;
  --color-primary-rgb: 36, 146, 242;
  --color-primary-contrast: #ffffff;
  --color-primary-contrast-rgb: 255, 255, 255;
  --color-primary-shade: #1b76c4;
  --color-primary-tint: #5ca3fa;
  --color-secondary: #191919;
  --color-secondary-rgb: 25, 25, 25;
  --color-secondary-contrast: #ffffff;
  --color-secondary-contrast-rgb: 255, 255, 255;
  --color-secondary-shade: #161616;
  --color-secondary-tint: #303030;
  --color-tertiary: #5260ff;
  --color-tertiary-rgb: 82, 96, 255;
  --color-tertiary-contrast: #ffffff;
  --color-tertiary-contrast-rgb: 255, 255, 255;
  --color-tertiary-shade: #4854e0;
  --color-tertiary-tint: #6370ff;
  --color-success: #2dd36f;
  --color-success-rgb: 45, 211, 111;
  --color-success-contrast: #ffffff;
  --color-success-contrast-rgb: 255, 255, 255;
  --color-success-shade: #28ba62;
  --color-success-tint: #42d77d;
  --color-warning: #ffc409;
  --color-warning-rgb: 255, 196, 9;
  --color-warning-contrast: #000000;
  --color-warning-contrast-rgb: 0, 0, 0;
  --color-warning-shade: #e0ac08;
  --color-warning-tint: #ffca22;
  --color-danger: #eb445a;
  --color-danger-rgb: 235, 68, 90;
  --color-danger-contrast: #ffffff;
  --color-danger-contrast-rgb: 255, 255, 255;
  --color-danger-shade: #cf3c4f;
  --color-danger-tint: #ed576b;
  --color-dark: #191919;
  --color-dark-rgb: 25, 25, 25;
  --color-dark-contrast: #ffffff;
  --color-dark-contrast-rgb: 255, 255, 255;
  --color-dark-shade: #161616;
  --color-dark-tint: #303030;
  --color-medium: #ced0ce;
  --color-medium-rgb: 206, 208, 206;
  --color-medium-contrast: #000000;
  --color-medium-contrast-rgb: 0, 0, 0;
  --color-medium-shade: #b5b7b5;
  --color-medium-tint: #d3d5d3;
  --color-light: #e6e8e6;
  --color-light-rgb: 230, 232, 230;
  --color-light-contrast: #000000;
  --color-light-contrast-rgb: 0, 0, 0;
  --color-light-shade: #caccca;
  --color-light-tint: #e9eae9;
  /** font-family **/
  --font-family-primary: "Montserrat", sans-serif;
  /** font-weights **/
  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-semibold: 600;
  /** font-sizes **/
  --font-size-small: 14px;
  --font-size-medium: 16px;
  --font-size-large: 24px;
  --font-size-huge: 42px;
  /** line-heights **/
  --line-height-small: 22px;
  --line-height-medium: 26px;
  --line-height-large: 36px;
  --line-height-huge: 56px;
  /** other **/
  --max-layout-width: 1200px;
  --page-edges-size: 20px;
}

* {
  outline: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

html, body, app-root {
  width: 100%;
  height: 100%;
  background-color: var(--color-light);
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-family-primary);
  overflow-x: hidden;
}
body.modal-opened {
  overflow: hidden;
}

.text-html h1, .text-html h2 {
  text-align: left;
  margin-bottom: 20px;
}
.text-html > div:not(:last-child), .text-html > ul:not(:last-child), .text-html > ol:not(:last-child) {
  margin-bottom: 50px;
}
.text-html > div div:not(:last-child), .text-html > div ul:not(:last-child), .text-html > div ol:not(:last-child), .text-html > ul div:not(:last-child), .text-html > ul ul:not(:last-child), .text-html > ul ol:not(:last-child), .text-html > ol div:not(:last-child), .text-html > ol ul:not(:last-child), .text-html > ol ol:not(:last-child) {
  margin-bottom: 20px;
}
.text-html div {
  text-align: left;
  font-size: var(--font-size-small);
  line-height: var(--line-height-small);
}
.text-html li {
  text-align: left;
  margin-bottom: 10px;
}
.text-html li ul, .text-html li ol {
  margin-top: 10px;
  margin-left: 30px;
}
.text-html ul {
  list-style-type: circle;
  padding-left: 40px;
}
.text-html ol {
  counter-reset: item;
  padding-left: 20px;
}
.text-html ol > li {
  display: block;
}
.text-html ol > li:before {
  content: counters(item, ".") ". ";
  counter-increment: item;
  margin-right: 5px;
  font-weight: 700;
}

hr {
  height: 1px;
  width: 100%;
  flex-shrink: 0;
  border: none;
  background-color: var(--color-light);
}
hr.bold {
  height: 2px;
  background-color: var(--color-dark);
}

[hidden], .hidden {
  opacity: 0 !important;
  height: 0px !important;
  margin: 0px !important;
  padding: 0px !important;
}

html, body {
  font-size: var(--font-size-small);
  line-height: var(--line-height-small);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-normal);
  text-align: center;
  color: var(--color-dark);
}

h1 {
  width: 100%;
  font-size: var(--font-size-large);
  line-height: var(--line-height-large);
  font-family: var(--font-family-primary);
  text-transform: uppercase;
  text-align: center;
  font-weight: var(--font-weight-semibold);
  color: var(--color-dark);
  margin: 0px;
}
h1.default-margin {
  margin-bottom: 15px;
}

h2 {
  font-size: var(--font-size-middle);
  line-height: var(--line-height-middle);
  letter-spacing: 0.3px;
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-semibold);
  text-align: center;
  text-transform: uppercase;
  color: var(--color-dark);
  margin: 0px;
}
h2.default-margin {
  margin-bottom: 15px;
}

label {
  color: var(--color-dark);
  margin: 0px;
  padding: 0px;
  font-size: var(--font-size-medium);
  line-height: var(--line-height-medium);
  width: 100%;
}
label * {
  text-transform: lowercase;
}

a {
  color: var(--color-primary);
  transition: all 0.3s ease-in-out;
}
a:hover {
  color: var(--color-primary-shade);
}