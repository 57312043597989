form, .form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  .form-fields-group {
    width: 100%;
  }

  app-input-text, app-input-select, app-input-unbound-select, app-action-button, .form-fields-group {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
}
