@import '~src/theme/variables';

* {
  outline: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

html, body, app-root {
  width: 100%;
  height: 100%;
  background-color: var(--color-light);
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-family-primary);
  overflow-x: hidden;

  &.modal-opened {
    overflow: hidden;
  }
}

.text-html {
  h1, h2 {
    text-align: left;
    margin-bottom: 20px;
  }

  & > {
    div, ul, ol {
      &:not(:last-child) {
        margin-bottom: 50px;
      }

      div, ul, ol {
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }
  }

  div {
    text-align: left;
    font-size: var(--font-size-small);
    line-height: var(--line-height-small);
  }

  li {
    text-align: left;
    margin-bottom: 10px;

    ul, ol {
      margin-top: 10px;
      margin-left: 30px;
    }
  }

  ul {
    list-style-type: circle;
    padding-left: 40px;
  }

  ol {
    counter-reset: item;
    padding-left: 20px;

    > li {
      display: block;

      &:before {
        content: counters(item, ".") ". ";
        counter-increment: item;
        margin-right: 5px;
        font-weight: 700;
      }
    }
  }
}

hr {
  height: 1px;
  width: 100%;
  flex-shrink: 0;
  border: none;
  background-color: var(--color-light);

  &.bold {
    height: 2px;
    background-color: var(--color-dark);
  }
}

[hidden], .hidden {
  opacity: 0 !important;
  height: 0px !important;
  margin: 0px !important;
  padding: 0px !important;
}
