@import './functions';

:root {
  /** colors **/
  --color-primary: #2492f2;
  --color-primary-rgb: 36, 146, 242;
  --color-primary-contrast: #ffffff;
  --color-primary-contrast-rgb: 255, 255, 255;
  --color-primary-shade: #1b76c4;
  --color-primary-tint: #5ca3fa;

  --color-secondary: #191919;
  --color-secondary-rgb: 25, 25, 25;
  --color-secondary-contrast: #ffffff;
  --color-secondary-contrast-rgb: 255, 255, 255;
  --color-secondary-shade: #161616;
  --color-secondary-tint: #303030;

  --color-tertiary: #5260ff;
  --color-tertiary-rgb: 82, 96, 255;
  --color-tertiary-contrast: #ffffff;
  --color-tertiary-contrast-rgb: 255, 255, 255;
  --color-tertiary-shade: #4854e0;
  --color-tertiary-tint: #6370ff;

  --color-success: #2dd36f;
  --color-success-rgb: 45, 211, 111;
  --color-success-contrast: #ffffff;
  --color-success-contrast-rgb: 255, 255, 255;
  --color-success-shade: #28ba62;
  --color-success-tint: #42d77d;

  --color-warning: #ffc409;
  --color-warning-rgb: 255, 196, 9;
  --color-warning-contrast: #000000;
  --color-warning-contrast-rgb: 0, 0, 0;
  --color-warning-shade: #e0ac08;
  --color-warning-tint: #ffca22;

  --color-danger: #eb445a;
  --color-danger-rgb: 235, 68, 90;
  --color-danger-contrast: #ffffff;
  --color-danger-contrast-rgb: 255, 255, 255;
  --color-danger-shade: #cf3c4f;
  --color-danger-tint: #ed576b;

  --color-dark: #191919;
  --color-dark-rgb: 25, 25, 25;
  --color-dark-contrast: #ffffff;
  --color-dark-contrast-rgb: 255, 255, 255;
  --color-dark-shade: #161616;
  --color-dark-tint: #303030;

  --color-medium: #ced0ce;
  --color-medium-rgb: 206, 208, 206;
  --color-medium-contrast: #000000;
  --color-medium-contrast-rgb: 0, 0, 0;
  --color-medium-shade: #b5b7b5;
  --color-medium-tint: #d3d5d3;

  --color-light: #e6e8e6;
  --color-light-rgb: 230, 232, 230;
  --color-light-contrast: #000000;
  --color-light-contrast-rgb: 0, 0, 0;
  --color-light-shade: #caccca;
  --color-light-tint: #e9eae9;

  /** font-family **/
  --font-family-primary: 'Montserrat', sans-serif;

  /** font-weights **/
  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-semibold: 600;

  /** font-sizes **/
  --font-size-small: 14px;
  --font-size-medium: 16px;
  --font-size-large: 24px;
  --font-size-huge: 42px;

  /** line-heights **/
  --line-height-small: 22px;
  --line-height-medium: 26px;
  --line-height-large: 36px;
  --line-height-huge: 56px;

  /** other **/
  --max-layout-width: 1200px;
  --page-edges-size: 20px;
}
